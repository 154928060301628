import React, { Component } from "react";
import ReactDOM from "react-dom";
import Routes from "../src/routes";
import { store } from "../src/store";
import { api } from "../src/api";
import Context from "../src/context";
import "react-bnb-gallery/dist/style.css";
import "react-tippy/dist/tippy.css";
import { Loader } from "../src/components";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { parseQuery } from "../src/utils";

const stripePromise = loadStripe(window.stripe_public_key);

class Root extends Component {
  constructor(props) {
    super(props);
    this.store = store;
    this.state = this.store.state;
    this.store.setStateHandler(this.setState.bind(this));
    this.query = parseQuery(window.location.search);
  }

  componentDidMount() {
    api.setDate(this.query.date);
    api.setAmbassador(
      this.query.ambassador || localStorage.getItem("ambassador")
    );
    api.getUser();
  }

  render() {
    if (this.state.loading.user) return <Loader />;

    return (
      <Context.Provider value={{ api, state: this.state }}>
        <Elements stripe={stripePromise}>
          <Routes />
        </Elements>
      </Context.Provider>
    );
  }
}

document.addEventListener("DOMContentLoaded", () => {
  ReactDOM.render(<Root />, document.getElementById("root"));
});
