import React from "react";

const FAQ = () => (
  <div className="container" style={{ marginBottom: "70px" }}>
    <h1 style={{ margin: "40px 0px 20px 0px" }}>Lake Hop FAQ</h1>
    <h2>Boat Owners</h2>
    <div className="title-small" style={{ margin: "40px 0px 10px 0px" }}>
      Do I need a license to rent out my boat?
    </div>
    <div className="subheader-light grey">
      No. However, it’s important to have proper insurance for guests to rent
      your boat.
    </div>
    <div className="title-small" style={{ margin: "40px 0px 10px 0px" }}>
      Do I need a license to ride share my boat?
    </div>
    <div className="subheader-light grey">
      Depending on what lake you offer your services on. If your lake is a U.S.
      Navigable waterway then the U.S.G.C rules state you need a captains
      license.
    </div>
    <div className="title-small" style={{ margin: "40px 0px 10px 0px" }}>
      Am I responsible for insurance?
    </div>
    <div className="subheader-light grey">
      Yes. You are responsible for having the appropriate insurance coverage to
      protect your boat, yourself, and your guests. There are many insurance
      carriers that offer this type of coverage. If you need an insurance
      recommendation, email us at{" "}
      <a href="mailto:support@golakehop.com">support@golakehop.com</a>.
    </div>
    <div className="title-small" style={{ margin: "40px 0px 10px 0px" }}>
      How much money can I make?
    </div>
    <div className="subheader-light grey">
      The potential is unlimited. You set your price and availability, so how
      much you make is up to you. Average earnings are $2,945 a month based on
      two trips a week at a $100/hr.
    </div>
    <div className="title-small" style={{ margin: "40px 0px 10px 0px" }}>
      How do I get paid?
    </div>
    <div className="subheader-light grey">
      Your money is direct deposited into your account at the completion of each
      trip.
    </div>
    <div className="title-small" style={{ margin: "40px 0px 10px 0px" }}>
      What info do I receive about the guests booking my trip?
    </div>
    <div className="subheader-light grey">
      You will receive their name, photo if available, number of guests coming,
      date & time of trip, as well as their goal for the trip. You have the
      ability to accept or deny any booking request.
    </div>
    <h2 style={{ marginTop: "40px" }}>Guests</h2>
    <div className="title-small" style={{ margin: "40px 0px 10px 0px" }}>
      Can I trust my captain?
    </div>
    <div className="subheader-light grey">
      Absolutely. Our captains are locals to the area and are knowledgeable in
      navigating every lake they offer experiences on. We also run a background
      check for each captain to ensure your safety.
    </div>
    <div className="title-small" style={{ margin: "40px 0px 10px 0px" }}>
      How much does it cost?
    </div>
    <div className="subheader-light grey">
      Each captain sets their own price per hour but we have trips starting as
      low as $20/hr. This includes fuel, captain services, and insurance
      coverage.
    </div>
    <div className="title-small" style={{ margin: "40px 0px 10px 0px" }}>
      Where does my money go?
    </div>
    <div className="subheader-light grey">
      The money is paid to the host/boat owner of the experience plus a service
      fee for booking the trip through Lake Hop.
    </div>
    <div className="title-small" style={{ margin: "40px 0px 10px 0px" }}>
      When do I get my damage deposit back?
    </div>
    <div className="subheader-light grey">
      The damage deposit hold is released 48hrs after the trip is complete and
      no damage claim has been filed by the host/boat owner.
    </div>
    <div className="title-small" style={{ margin: "40px 0px 10px 0px" }}>
      What happens if there is bad weather?
    </div>
    <div className="subheader-light grey">
      If there is inclement weather on the day of your trip and the host/boat
      owner cancels, 100% of your money will be refunded to you.
    </div>
    <div className="title-small" style={{ margin: "40px 0px 10px 0px" }}>
      Are captains allowed to drink during a trip?
    </div>
    <div className="subheader-light grey">
      No absolutely not, our captains are there to keep you and your party safe
      so you can focus on having fun.
    </div>
  </div>
);

export default FAQ;
